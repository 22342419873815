import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Icon, Strong, List, Section, Em, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				КодМайстер
			</title>
			<meta name={"description"} content={"Ми допоможемо вам опанувати сучасні технології та навички, необхідні для успішної кар'єри у сфері IT. "} />
			<meta property={"og:title"} content={"КодМайстер"} />
			<meta property={"og:description"} content={"Ми допоможемо вам опанувати сучасні технології та навички, необхідні для успішної кар'єри у сфері IT. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/image-91.png?v=2024-06-18T12:51:15.628Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1">
				ПОСЛУГИ
			</Override>
		</Components.Header>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-16"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="center"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 60px 0px"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text as="p" font="--base" color="--orange" margin="0px 0px 8px 0px">
					Що ми пропонуємо
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
					sm-text-align="left"
				>
					Наші послуги
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--light"
					width="70%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="center"
					sm-text-align="left"
				>
					Ми пропонуємо широкий спектр курсів, які допоможуть вам опанувати навички програмування та підготуватися до успішної кар'єри в IT-індустрії. Наші курси розроблені для студентів різного рівня підготовки – від початківців до досвідчених професіоналів. Кожен курс включає детальні теоретичні матеріали, практичні завдання та реальні проекти, що дозволяє закріпити отримані знання на практиці.
				</Text>
			</Box>
			<Box
				display="flex"
				width="100%"
				lg-justify-content="center"
				lg-width="100%"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				justify-content="center"
				margin="0px 0px 36px 0px"
				position="relative"
			>
				<Box
					height="553px"
					width="100%"
					position="absolute"
					bottom="-0.239501953125px"
					top="0px"
				/>
				<Image
					src="https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/Populiarni-movy-prohramuvannia-sered-rozrobnykiv.jpg?v=2024-06-18T12:51:15.600Z"
					display="block"
					margin="0px 0px 0px 0px"
					border-radius="16px"
					srcSet="https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/Populiarni-movy-prohramuvannia-sered-rozrobnykiv.jpg?v=2024-06-18T12%3A51%3A15.600Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/Populiarni-movy-prohramuvannia-sered-rozrobnykiv.jpg?v=2024-06-18T12%3A51%3A15.600Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/Populiarni-movy-prohramuvannia-sered-rozrobnykiv.jpg?v=2024-06-18T12%3A51%3A15.600Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/Populiarni-movy-prohramuvannia-sered-rozrobnykiv.jpg?v=2024-06-18T12%3A51%3A15.600Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/Populiarni-movy-prohramuvannia-sered-rozrobnykiv.jpg?v=2024-06-18T12%3A51%3A15.600Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/Populiarni-movy-prohramuvannia-sered-rozrobnykiv.jpg?v=2024-06-18T12%3A51%3A15.600Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667168f18e2e8e00217d0cb7/images/Populiarni-movy-prohramuvannia-sered-rozrobnykiv.jpg?v=2024-06-18T12%3A51%3A15.600Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<List
				margin="40px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				as="ul"
				sm-margin="24px 0 0 0"
				list-style-type="none"
				font="normal normal 18px/150% sans-serif"
				display="grid"
				flex-direction="column"
				grid-gap="25px"
				lg-margin="32px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				lg-grid-template-columns="1fr"
			>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdCheckCircle}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong>
							Курси веб-розробки
						</Strong>
						<br />
						<br />
						У нашій школі "КодМайстер" ви можете опанувати всі аспекти веб-розробки. Ми пропонуємо курси HTML, CSS, JavaScript, а також більш просунуті курси з використанням фреймворків, таких як React, Angular та Vue.js. Наші курси включають як теоретичні лекції, так і практичні завдання, що дозволяють закріпити отримані знання.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdCheckCircle}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong>
							Курси мобільної розробки
						</Strong>
						<br />
						<br />
						Ми пропонуємо курси мобільної розробки для платформ iOS та Android. Ви навчитеся створювати мобільні додатки з нуля, використовуючи мови програмування Swift та Kotlin, а також освоїте роботу з фреймворками, такими як Flutter та React Native. Наші інструктори мають великий досвід у розробці мобільних додатків і поділяться з вами своїми знаннями та секретами.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdCheckCircle}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						font="18px/27px --fontFamily-sans"
						color="--light"
						lg-max-width="720px"
					>
						<Strong>
							Курси з машинного навчання
						</Strong>
						<br />
						<br />
						"КодМайстер" також пропонує курси з машинного навчання та аналізу даних. Ви дізнаєтеся про основи машинного навчання, нейронні мережі, обробку великих даних та багато іншого. Наші курси включають реальні проекти, що дозволяють застосувати отримані знання на практиці. Ви навчитеся використовувати популярні бібліотеки та інструменти, такі як TensorFlow, Keras та PyTorch.
					</Text>
				</Box>
			</List>
			<List
				margin="40px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				as="ul"
				sm-margin="24px 0 0 0"
				list-style-type="none"
				font="normal normal 18px/150% sans-serif"
				display="grid"
				flex-direction="column"
				grid-gap="25px"
				lg-margin="28px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				lg-grid-template-columns="1fr"
			>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdCheckCircle}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong>
							Курси з аналізу даних
						</Strong>
						<br />
						<br />
						Курси з аналізу даних у "КодМайстер" допоможуть вам освоїти методи та інструменти для обробки та аналізу даних. Ви навчитеся працювати з мовами програмування Python та R, використовувати SQL для роботи з базами даних, а також освоїте інструменти для візуалізації даних, такі як Tableau та Power BI. Наші курси орієнтовані на практичне застосування знань, що допоможе вам стати кваліфікованим аналітиком даних.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdCheckCircle}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong>
							Підготовка до сертифікацій
						</Strong>
						<br />
						<br />
						Ми також пропонуємо курси з підготовки до міжнародних сертифікацій у сфері IT. Ви зможете підготуватися до таких сертифікацій, як AWS Certified Solutions Architect, Microsoft Certified: Azure Fundamentals, Cisco Certified Network Associate (CCNA) та інших. Наші інструктори допоможуть вам зрозуміти вимоги сертифікаційних іспитів та нададуть всі необхідні матеріали для успішної підготовки.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdCheckCircle}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						font="18px/27px --fontFamily-sans"
						color="--light"
						lg-max-width="720px"
					>
						<Strong>
							{" "}Індивідуальні консультації
						</Strong>
						<br />
						<br />
						Для тих, хто потребує індивідуального підходу, ми пропонуємо послуги індивідуальних консультацій з нашими інструкторами. Ви зможете отримати відповіді на всі ваші питання, обговорити складні теми та отримати персональні рекомендації для вашого навчання та розвитку у сфері IT. Індивідуальні консультації допоможуть вам максимально ефективно використовувати час та ресурси для досягнення ваших цілей.
					</Text>
				</Box>
			</List>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/667168f18e2e8e00217d0cb7/images/programming-language_1160-100052401-orig-001_i.jpg?v=2024-06-18T12:51:15.602Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Приєднуйтеся до "КодМайстер" сьогодні та почніть свій шлях до успішної кар'єри в IT.{" "}
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Не впевнені, який курс вибрати? Замовте безкоштовну консультацію з нашим інструктором, який допоможе вам визначити ваші цілі та підібрати найкращий курс для вас. Телефонуйте, щоб зв'язатися з нами.
					<br />
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text1" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});